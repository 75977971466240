<!--
    @name: formList
    @description：formList
    @author: ZengWei
    @date: 2021-11-26 15:18
-->
<template>
  <div style="padding: 15px">
    <div>
      <div style="display: flex; justify-content: space-between">
        <div>
          <el-form :inline="true" size="mini" class="demo-form-inline">
            <el-form-item label="">
              <el-input
                v-model="search"
                placeholder="请输入表单名称或UUID"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" size="mini" @click="addDesign"
            >添加表单设计</el-button
          >
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      height="calc(100% - 120px)"
      v-loading="tableLoading"
    >
      <el-table-column prop="id" label="序号" width="180"> </el-table-column>
      <el-table-column prop="module_name" label="表单名称"> </el-table-column>
      <el-table-column prop="module_uuid" label="表单唯一标识">
      </el-table-column>
      <el-table-column prop="created_at" label="添加时间"> </el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              type="warning"
              size="mini"
              @click="turnToVirtualObject(scope)"
              >对象</el-button
            >
            <el-button type="primary" size="mini" @click="editDesign(scope.row)"
              >修改</el-button
            >
            <el-button type="primary" size="mini" @click="turnToDesign(scope)"
              >设计</el-button
            >
            <el-button type="danger" size="mini" @click="migrateDesign(scope)"
              >迁移</el-button
            >
            <el-button type="danger" size="mini" @click="delDesign(scope)"
              >删除</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <div style="position: fixed; bottom: 50px; right: 50px">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="15"
        :current-page="parseInt(currentPage)"
        @current-change="changePage"
      >
      </el-pagination>
    </div>

    <el-dialog title="添加表单设计" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="表单名称">
          <el-input v-model="form.module_name"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formDesign } from "@/api/home";
import objectOperationMixin from "../objects/objectOperationMixin.js";
import { ButtonGroup } from "element-ui";

export default {
  name: "formList",
  mixins: [objectOperationMixin],
  components: {
    "el-button-group": ButtonGroup,
  },
  props: {},
  data() {
    return {
      tableLoading: false,
      dialogVisible: false,
      tableData: [],
      total: 0,
      currentPage: this.$route.query.page,
      form: {
        module_name: "",
      },
      designId: 0,
      rules: {
        module_name: [
          { required: true, message: "请输入表单名称", trigger: "blur" },
        ],
      },
      search: "",
    };
  },
  mounted() {
    let param = { page: this.$route.query.page || 1 };
    this.getAll(param);
  },
  methods: {
    getAll(param = {}) {
      this.tableLoading = true;
      formDesign
        .formList(param)
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    changePage(page) {
      this.$router.push({ path: "/dwh/formDesignList", query: { page: page } });
      let param = { page: page };
      this.getAll(param);
    },
    addDesign() {
      this.designId = 0;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
    },
    submitHandle() {
      this.tableLoading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.designId === 0) {
            let data = { module_name: this.form.module_name };
            formDesign.formStore(data).then((res) => {
              if (res.data.code === 200) {
                this.dialogVisible = false;
                this.getAll();
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
              this.tableLoading = false;
            });
          } else {
            let data = { module_name: this.form.module_name };
            formDesign.formUpdate(this.designId, data).then((res) => {
              if (res.data.code === 200) {
                this.dialogVisible = false;
                this.getAll();
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
              this.tableLoading = false;
            });
          }
        }
      });
    },
    turnToDesign(scope) {
      this.$router.push({
        name: "formTestDesign",
        query: { designId: scope.row.module_uuid },
      });
    },
    turnToVirtualObject(scope) {
      this.$router.push({
        path: "/dwh/objects/detail",
        query: {
          obuuid: scope.row.module_uuid,
          obname: scope.row.module_name,
          obcode: scope.row.module_uuid,
          type: "field",
          isVirtual: 1,
        },
      });
    },
    editDesign(row) {
      this.dialogVisible = true;
      this.designId = row.module_uuid;
      this.form.module_name = row.module_name;
    },
    searchList() {
      let param = { name: this.search, page: this.page };
      this.getAll(param);
    },
    delDesign(scope) {
      this.$confirm("请确认要删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableLoading = true;
          let id = scope.row.id;
          let ind = scope.$index;
          formDesign.formDel(id).then((res) => {
            if (res.data.code == 200) {
              this.tableData.splice(ind, 1);
              this.$message({
                type: "success",
                message: res.data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: res.data.msg,
              });
            }
            this.tableLoading = false;
          });
        })
        .catch(() => {});
    },
    migrateDesign(scope) {
      this.copyFormDesign(scope.row.module_uuid);
    },
  },
};
</script>

<style scoped>
</style>
